import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Tabs, Tab } from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";

// Tarihi Türkiye saatine göre formatlayan fonksiyon
const formatDateToTR = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  const options = {
    timeZone: 'Europe/Istanbul',
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  };
  return date.toLocaleString('tr-TR', options).replace(',', '');
};

const UserReportPage = () => {
  const { getAccessTokenSilently, user } = useAuth0();
  const [reportData, setReportData] = useState([]);
  const [error, setError] = useState(null);
  const [selectedReport, setSelectedReport] = useState('driver'); // Başlangıçta Sürücü Raporu seçili

  useEffect(() => {
    const fetchReportData = async () => {
      try {
        const token = await getAccessTokenSilently(); // JWT token'ı Auth0'dan alın
        const response = await axios.get(selectedReport === 'driver' ? `${process.env.REACT_APP_API_URL}/driver-report` : `${process.env.REACT_APP_API_URL}/vehicle-dispatch-report`, {
          headers: {
            Authorization: `Bearer ${token}`, // Authorization başlığını ekleyin
            'x-user-email': user.email // Kullanıcının e-posta adresini başlık olarak ekleyin
          }
        });
        setReportData(response.data);
      } catch (error) {
        setError('Rapor verileri çekilirken bir hata oluştu.');
        console.error(error);
      }
    };

    fetchReportData();
  }, [selectedReport, getAccessTokenSilently, user.email]);

  const handleTabChange = (event, newValue) => {
    setSelectedReport(newValue);
    setReportData([]);
    setError(null);
  };

  return (
    <Container maxWidth="md" sx={{ padding: { xs: '10px', sm: '20px' }, marginTop: { xs: '20px', sm: '30px' } }}>
      <Paper elevation={3}>
        <Typography variant="h4" align="center" gutterBottom>
          Raporlar
        </Typography>

        <Tabs value={selectedReport} onChange={handleTabChange} centered>
          <Tab label="Sürücü Raporu" value="driver" />
          <Tab label="Araç Sevkiyat Raporu" value="vehicle" />
        </Tabs>

        {error && (
          <Typography variant="body1" color="error" align="center">
            {error}
          </Typography>
        )}

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              {selectedReport === 'driver' ? (
                <TableRow>
                  <TableCell><strong>Sürücü Adı</strong></TableCell>
                  <TableCell><strong>Plaka</strong></TableCell>
                  <TableCell><strong>Toplam Sevkiyat</strong></TableCell>
                  <TableCell><strong>Toplam Sefer Sayısı</strong></TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell><strong>Plaka</strong></TableCell>
                  <TableCell><strong>Sürücü Adı</strong></TableCell>
                  <TableCell><strong>Durum</strong></TableCell>
                  <TableCell><strong>Atama Zamanı</strong></TableCell>
                  <TableCell><strong>Yükleme Zamanı</strong></TableCell>
                  <TableCell><strong>Varış Zamanı</strong></TableCell>
                  <TableCell><strong>Çıkış Zamanı</strong></TableCell>
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {reportData.map((row, index) => (
                <TableRow key={index}>
                  {selectedReport === 'driver' ? (
                    <React.Fragment>
                      <TableCell>{row.driver_name}</TableCell>
                      <TableCell>{row.plaka}</TableCell>
                      <TableCell>{row.total_dispatches}</TableCell>
                      <TableCell>{row.total_trip_count}</TableCell>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <TableCell>{row.plaka}</TableCell>
                      <TableCell>{row.driver_name}</TableCell>
                      <TableCell>{row.status}</TableCell>
                      <TableCell>{formatDateToTR(row.assigned_at)}</TableCell> {/* Atama Zamanı */}
                      <TableCell>{formatDateToTR(row.loaded_at)}</TableCell> {/* Yükleme Zamanı */}
                      <TableCell>{formatDateToTR(row.arrived_at)}</TableCell> {/* Varış Zamanı */}
                      <TableCell>{formatDateToTR(row.exited_at)}</TableCell> {/* Çıkış Zamanı */}
                    </React.Fragment>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};

export default UserReportPage;