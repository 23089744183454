import React, { useState, useEffect } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";

import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { useAuth0 } from "@auth0/auth0-react";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const { user, isAuthenticated, loginWithRedirect, logout, getAccessTokenSilently } = useAuth0();
  const toggle = () => setIsOpen(!isOpen);

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      }
    });

  useEffect(() => {
    const fetchUserRole = async () => {
      if (isAuthenticated && user?.email) {
        try {
          const token = await getAccessTokenSilently();
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/getUserDetails`, {
            headers: { 
              'Authorization': `Bearer ${token}`,
              'x-user-email': user.email 
            }
          });
          setUserRole(response.data.role);
        } catch (error) {
          console.error('Kullanıcı rolü alınamadı:', error);
        }
      }
    };

    fetchUserRole();
  }, [isAuthenticated, user, getAccessTokenSilently]);

  const handleNavLinkClick = (event) => {
    if (isOpen) {
      toggle();
    }
    // Sayfanın yeniden yüklenmesini sağla
    window.location.href = event.currentTarget.href;
  };

  return (
    <div className="nav-container">
      <Navbar color="light" light expand="md" container={false}>
        <Container>
          <NavbarBrand className="logo" />
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink
                  tag={RouterNavLink}
                  to="/"
                  exact
                  activeClassName="router-link-exact-active"
                  onClick={handleNavLinkClick}
                >
                  AnaSayfa
                </NavLink>
              </NavItem>
              
              {isAuthenticated && userRole === 'admin' && (
                <>
                  <NavItem>
                    <NavLink
                      tag={RouterNavLink}
                      to="/addvehicletolist"
                      exact
                      activeClassName="router-link-exact-active"
                      onClick={handleNavLinkClick}
                    >
                      Listeye Araç Ekle
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={RouterNavLink}
                      to="/vehiclelist"
                      exact
                      activeClassName="router-link-exact-active"
                      onClick={handleNavLinkClick}
                    >
                      Araç Listesi
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={RouterNavLink}
                      to="/createjob"
                      exact
                      activeClassName="router-link-exact-active"
                      onClick={handleNavLinkClick}
                    >
                      İş Oluştur
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={RouterNavLink}
                      to="/addvehicletojob"
                      exact
                      activeClassName="router-link-exact-active"
                      onClick={handleNavLinkClick}
                    >
                      İşe Araç Ekle
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={RouterNavLink}
                      to="/statusmanagment"
                      exact
                      activeClassName="router-link-exact-active"
                      onClick={handleNavLinkClick}
                    >
                      Durumlar
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={RouterNavLink}
                      to="/operatormanagement"
                      exact
                      activeClassName="router-link-exact-active"
                      onClick={handleNavLinkClick}
                    >
                      Operatörler
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={RouterNavLink}
                      to="/jobvehiclelist"
                      exact
                      activeClassName="router-link-exact-active"
                      onClick={handleNavLinkClick}
                    >
                      Pancar Listesi
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={RouterNavLink}
                      to="/createnewlistno"
                      exact
                      activeClassName="router-link-exact-active"
                      onClick={handleNavLinkClick}
                    >
                      Yeni Liste
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={RouterNavLink}
                      to="/vehicledispatchpage"
                      exact
                      activeClassName="router-link-exact-active"
                      onClick={handleNavLinkClick}
                    >
                      Araç Gönder
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={RouterNavLink}
                      to="/adminreportpage"
                      exact
                      activeClassName="router-link-exact-active"
                      onClick={handleNavLinkClick}
                    >
                      Raporlar
                    </NavLink>
                  </NavItem>
                </>
              )}
              {isAuthenticated && userRole === 'operator' && (
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/operatordispatchespage"
                    exact
                    activeClassName="router-link-exact-active"
                    onClick={handleNavLinkClick}
                  >
                    Operatör Sayfası
                  </NavLink>
                </NavItem>
              )}
            </Nav>
            <Nav className="d-none d-md-block" navbar>
              {!isAuthenticated && (
                <NavItem>
                  <Button
                    id="qsLoginBtn"
                    color="primary"
                    className="btn-margin"
                    onClick={() => loginWithRedirect()}
                  >
                    Log in
                  </Button>
                </NavItem>
              )}
              {isAuthenticated && (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret id="profileDropDown">
                    <img
                      src={user.picture}
                      alt="Bilgilerim"
                      className="nav-user-profile rounded-circle"
                      width="50"
                    />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem header>{user.name}</DropdownItem>
                    <DropdownItem
                          tag={RouterNavLink}
                          to="/profile"
                          className="dropdown-profile"
                          activeClassName="router-link-exact-active"
                          onClick={handleNavLinkClick}
                        >
                          <FontAwesomeIcon icon="user" className="mr-3" /> Bilgilerim
                        </DropdownItem>
                    {userRole === 'user' && (
                      <>
                        <DropdownItem
                          tag={RouterNavLink}
                          to="/jobvehiclelist"
                          className="dropdown-profile"
                          activeClassName="router-link-exact-active"
                          onClick={handleNavLinkClick}
                        >
                          <FontAwesomeIcon icon="user" className="mr-3" /> Pancar Sırası
                        </DropdownItem>
                        <DropdownItem
                          tag={RouterNavLink}
                          to="/driverstatuspage"
                          className="dropdown-profile"
                          activeClassName="router-link-exact-active"
                          onClick={handleNavLinkClick}
                        >
                          <FontAwesomeIcon icon="user" className="mr-3" /> Durum Güncelle
                        </DropdownItem>
                        
                        <DropdownItem
                          tag={RouterNavLink}
                          to="/userreportpage"
                          className="dropdown-profile"
                          activeClassName="router-link-exact-active"
                          onClick={handleNavLinkClick}
                        >
                          <FontAwesomeIcon icon="user" className="mr-3" /> Raporlar
                        </DropdownItem>
                      </>
                    )}
                    <DropdownItem
                      id="qsLogoutBtn"
                      onClick={() => logoutWithRedirect()}
                    >
                      <FontAwesomeIcon icon="power-off" className="mr-3" /> Çıkış
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </Nav>
            {!isAuthenticated && (
              <Nav className="d-md-none" navbar>
                <NavItem>
                  <Button
                    id="qsLoginBtn"
                    color="primary"
                    block
                    onClick={() => loginWithRedirect({})}
                  >
                    Log in
                  </Button>
                </NavItem>
              </Nav>
            )}
            {isAuthenticated && (
              <Nav
                className="d-md-none justify-content-between"
                navbar
                style={{ minHeight: 170 }}
              >
                <NavItem>
                  <span className="user-info">
                    <img
                      src={user.picture}
                      alt="Bilgilerim"
                      className="nav-user-profile d-inline-block rounded-circle mr-3"
                      width="50"
                    />
                    <h6 className="d-inline-block">{user.name}</h6>
                  </span>
                </NavItem>
                
                {userRole === 'user' && (
                  <>
                    <NavItem>
                      <FontAwesomeIcon icon="user" className="mr-3" />
                      <RouterNavLink
                        to="/jobvehiclelist"
                        activeClassName="router-link-exact-active"
                        onClick={handleNavLinkClick}
                      >
                        Pancar Sırası
                      </RouterNavLink>
                    </NavItem>
                    <NavItem>
                      <FontAwesomeIcon icon="user" className="mr-3" />
                      <RouterNavLink
                        to="/driverstatuspage"
                        activeClassName="router-link-exact-active"
                        onClick={handleNavLinkClick}
                      >
                        Durum Güncelle
                      </RouterNavLink>
                    </NavItem>
                    <NavItem>
                      <FontAwesomeIcon icon="user" className="mr-3" />
                      <RouterNavLink
                        to="/profile"
                        activeClassName="router-link-exact-active"
                        onClick={handleNavLinkClick}
                      >
                        Bilgilerim
                      </RouterNavLink>
                    </NavItem>
                    <NavItem>
                      <FontAwesomeIcon icon="user" className="mr-3" />
                      <RouterNavLink
                        to="/reportpage"
                        activeClassName="router-link-exact-active"
                        onClick={handleNavLinkClick}
                      >
                        Raporlar
                      </RouterNavLink>
                    </NavItem>
                  </>
                )}
                <NavItem>
                  <FontAwesomeIcon icon="power-off" className="mr-3" />
                  <RouterNavLink
                    to="#"
                    id="qsLogoutBtn"
                    onClick={() => logoutWithRedirect()}
                  >
                    Çıkış
                  </RouterNavLink>
                </NavItem>
              </Nav>
            )}
          </Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavBar;