import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { Container, Typography, Box, Select, MenuItem, Button, Paper } from '@mui/material';
import io from 'socket.io-client';

const DriverStatusPage = () => {
  const { user, isAuthenticated } = useAuth0(); // Auth0'dan kullanıcı bilgisi alınıyor
  const [dispatchData, setDispatchData] = useState([]);
  const [newStatus, setNewStatus] = useState('');
  const [vehicleId, setVehicleId] = useState(null);
  const [selectedDispatchId, setSelectedDispatchId] = useState(null); // Seçilen dispatch_id

  useEffect(() => {
    const fetchDispatchData = async () => {
      if (!isAuthenticated || !user?.email) return;
  
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/getDispatchesByEmail`, {
          params: { email: user.email }
        });
  
        // Sadece status verisi dolu olanları ve status değeri 'ÇIKTI' olmayanları filtrele
        const filteredDispatchData = response.data.filter(dispatch => dispatch.status && dispatch.status !== 'ÇIKTI');
        setDispatchData(filteredDispatchData);
  
        if (filteredDispatchData.length > 0) {
          const dispatch = filteredDispatchData[0]; // İlk dispatch verisini alalım
          setVehicleId(dispatch.vehicle_id); // Vehicle ID'yi set ediyoruz
          setSelectedDispatchId(dispatch.dispatch_id); // İlk dispatch_id'yi set ediyoruz
  
          // Status'e göre yeni statü seçeneklerini ayarla
          if (dispatch.status === 'FABRİKADA') {
            setNewStatus('ÇIKTI');
          } else {
            setNewStatus('FABRİKADA');
          }
        }
      } catch (error) {
        console.error('Hata:', error);
      }
    };
  
    fetchDispatchData();

    // Socket.IO client initialization
    const socket = io(process.env.REACT_APP_SOCKET_URL || 'http://localhost:3001');

    // Listen for 'data_changed' event
    socket.on('data_changed', () => {
      console.log('Data changed, fetching updated dispatch data...');
      fetchDispatchData(); // Refresh the dispatch data
    });

    // Cleanup on component unmount
    return () => {
      socket.off('data_changed');
      socket.disconnect();
    };
  }, [isAuthenticated, user]);

  const handleStatusUpdate = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/updateVehicleStatusByDriver`, {
        vehicleId: vehicleId || null,
        status: newStatus || null,
        email: user.email || null,
        dispatchId: selectedDispatchId || null
      });

      if (response.status === 200) {
        console.log('Durum başarıyla güncellendi.');
        const socket = io(process.env.REACT_APP_SOCKET_URL || 'http://localhost:3001');
        socket.emit('data_changed');
        window.location.reload();
      }
    } catch (error) {
      console.error('Hata:', error);
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} style={{ padding: '16px', marginTop: '24px' }}>
        <Typography variant="h4" align="center" gutterBottom>
          Durum Güncelle
        </Typography>

        {dispatchData.length > 0 ? (
          dispatchData.map((dispatch) => (
            <Box key={dispatch.dispatch_id} mb={2}>
              <Typography variant="h6">
                Araç ID: {dispatch.vehicle_id}
              </Typography>
              <Typography variant="subtitle1">
                Son Durum: {dispatch.status}
              </Typography>
            </Box>
          ))
        ) : (
          <Typography variant="body1">Henüz güncellencek bir durum yok.</Typography>
        )}

        {newStatus && (
          <Box mt={4}>
            <Typography variant="h6" gutterBottom>
              Yeni Durum:
            </Typography>
            <Select
              value={newStatus}
              onChange={(e) => setNewStatus(e.target.value)}
              fullWidth
            >
              <MenuItem value="FABRİKADA">FABRİKADA</MenuItem>
              <MenuItem value="ÇIKTI">ÇIKTI</MenuItem>
            </Select>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleStatusUpdate}
              style={{ marginTop: '16px' }}
            >
              Güncelle
            </Button>
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default DriverStatusPage;