import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Typography, Box, TextField, Button, Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Grid2 from '@mui/material/Grid2';


const OperatorManagement = () => {
  const [operators, setOperators] = useState([]);
  const [newOperator, setNewOperator] = useState({
    operator_name: '',
    machine_name: '',
    driver_id: '',
  });

  useEffect(() => {
    const fetchOperators = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/operators`);
        setOperators(response.data);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchOperators();
  }, []);

  const handleAddOperator = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/operators`, newOperator);
      setOperators([...operators, response.data]);
      setNewOperator({ operator_name: '', machine_name: '', driver_id: '' });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleDeleteOperator = async (operator_id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/operators/${operator_id}`);
      setOperators(operators.filter(operator => operator.operator_id !== operator_id));
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleEditOperator = async (operator_id, updatedOperator) => {
    try {
      console.log('Sending PUT request to update operator with ID:', operator_id); // Debugging line
      console.log('Updated operator data:', updatedOperator); // Debugging line
      await axios.put(`${process.env.REACT_APP_API_URL}/operators/${operator_id}`, updatedOperator);
      setOperators(operators.map(operator => (operator.operator_id === operator_id ? updatedOperator : operator)));
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const columns = [
    { field: 'operator_name', headerName: 'Operator Name', width: 150, editable: true },
    { field: 'machine_name', headerName: 'Machine Name', width: 150, editable: true },
    { field: 'driver_id', headerName: 'Driver ID', width: 150, editable: true },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <div>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleDeleteOperator(params.row.operator_id)}
            size="small"
          >
            SİL
          </Button>
        </div>
      ),
    },
  ];

  const handleProcessRowUpdate = (newRow) => {
    handleEditOperator(newRow.operator_id, newRow);
    return newRow;
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom style={{ fontSize: '1.5rem' }}>
        Operatör Yönetimi
      </Typography>
      <Box mb={2} p={2} style={{ backgroundColor: '#f5f5f5', borderRadius: '8px' }}>
        <Grid2 container spacing={2} alignItems="center" style={{ border: 'none' }}>
          <Grid2 item xs={12} sm={4}>
            <TextField
              label="Operatör Adı"
              value={newOperator.operator_name}
              onChange={(e) => setNewOperator({ ...newOperator, operator_name: e.target.value })}
              variant="outlined"
              size="small"
              fullWidth
              slotProps={{
                inputLabel: { style: { fontSize: '0.875rem' } },
                input: { style: { fontSize: '0.875rem' } },
              }}
            />
          </Grid2>
          <Grid2 item xs={12} sm={4}>
            <TextField
              label="Makina Adı"
              value={newOperator.machine_name}
              onChange={(e) => setNewOperator({ ...newOperator, machine_name: e.target.value })}
              variant="outlined"
              size="small"
              fullWidth
              slotProps={{
                inputLabel: { style: { fontSize: '0.875rem' } },
                input: { style: { fontSize: '0.875rem' } },
              }}
            />
          </Grid2>
          <Grid2 item xs={12} sm={4}>
            <TextField
              label="Kullanıcı ID"
              value={newOperator.driver_id}
              onChange={(e) => setNewOperator({ ...newOperator, driver_id: e.target.value })}
              variant="outlined"
              size="small"
              fullWidth
              slotProps={{
                inputLabel: { style: { fontSize: '0.875rem' } },
                input: { style: { fontSize: '0.875rem' } },
              }}
            />
          </Grid2>
          <Grid2 item xs={12}>
            <Button variant="contained" color="primary" onClick={handleAddOperator} fullWidth size="small">
              Ekle
            </Button>
          </Grid2>
        </Grid2>
      </Box>
      <Paper style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={operators}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
          disableSelectionOnClick
          processRowUpdate={handleProcessRowUpdate}
          experimentalFeatures={{ newEditingApi: true }}
          getRowId={(row) => row.operator_id}
          sx={{
            '& .MuiDataGrid-root': {
              fontSize: '0.875rem',
            },
            '& .MuiDataGrid-columnHeaders': {
              fontSize: '0.875rem',
            },
            '& .MuiDataGrid-cell': {
              fontSize: '0.875rem',
            },
            '& .MuiButton-root': {
              fontSize: '0.75rem',
            },
          }}
        />
      </Paper>
    </Container>
  );
};

export default OperatorManagement;