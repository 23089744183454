import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, MenuItem, Select, FormControl, InputLabel, Modal, Box, Button } from '@mui/material';
import io from 'socket.io-client';

// Socket.io bağlantısını başlat
const socket = io(process.env.REACT_APP_SOCKET_URL || 'http://localhost:3001', {
  transports: ['websocket', 'polling']
});

const OperatorDispatchesPage = () => {
  const [operators, setOperators] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState('');
  const [dispatches, setDispatches] = useState([]);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedDispatch, setSelectedDispatch] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('YÜKLENDİ');

  useEffect(() => {
    const fetchOperators = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/operators`);
        setOperators(response.data);
      } catch (error) {
        setError('Operatör adları çekilirken bir hata oluştu.');
        console.error(error);
      }
    };

    fetchOperators();

    // Socket.io olaylarını dinle
    socket.on('dispatchStatusUpdated', (data) => {
      setDispatches((prevDispatches) =>
        prevDispatches.map((dispatch) =>
          dispatch.dispatch_id === data.dispatch_id
            ? { ...dispatch, status: data.status, loaded_at: data.loaded_at }
            : dispatch
        )
      );
    });

    socket.on('operator_assigned', (data) => {
      setDispatches((prevDispatches) =>
        prevDispatches.map((dispatch) =>
          dispatch.dispatch_id === data.dispatch_id
            ? { ...dispatch, operatorName: data.operatorName, assigned_at: data.assigned_at }
            : dispatch
        )
      );
    });

    return () => {
      socket.off('dispatchStatusUpdated');
      socket.off('operator_assigned');
    };
  }, []);

  const handleOperatorChange = async (event) => {
    const operatorId = event.target.value;
    setSelectedOperator(operatorId);

    fetchDispatches(operatorId);
  };

  const fetchDispatches = async (operatorId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/dispatchesByOperator/${operatorId}`);
      const sortedDispatches = response.data.sort((a, b) => new Date(a.assigned_at) - new Date(b.assigned_at));
      setDispatches(sortedDispatches);
    } catch (error) {
      setError('Veri çekilirken bir hata oluştu.');
      console.error(error);
    }
  };

  const handleRowClick = (dispatch) => {
    setSelectedDispatch(dispatch);
    setSelectedStatus('YÜKLENDİ'); // Modal açıldığında varsayılan durumu sıfırla
    setOpenModal(true);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleUpdateStatus = async () => {
    if (!selectedDispatch || !selectedStatus) {
      setError('Lütfen bir durum seçin.');
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/updateDispatchByOperator`, {
        dispatch_id: selectedDispatch.dispatch_id,
        status: selectedStatus,
      });

      setOpenModal(false);
      fetchDispatches(selectedOperator); // Güncellenmiş verileri tekrar çek
    } catch (error) {
      setError('Durum güncellenirken bir hata oluştu.');
      console.error(error);
    }
  };

  const formatDateToTR = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const options = {
      timeZone: 'Europe/Istanbul',
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    };
    return date.toLocaleString('tr-TR', options).replace(',', '');
  };

  return (
    <Container maxWidth="md" sx={{ padding: { xs: '10px', sm: '20px' }, marginTop: { xs: '20px', sm: '30px' } }}>
      <Paper elevation={3}>
        <Typography variant="h4" align="center" gutterBottom>
          Operatör Sevkiyatları
        </Typography>

        {error && (
          <Typography variant="body1" color="error" align="center">
            {error}
          </Typography>
        )}

        <FormControl fullWidth sx={{ marginBottom: { xs: '10px', sm: '20px' } }}>
          <InputLabel id="operator-select-label">Operatör Seç</InputLabel>
          <Select
            labelId="operator-select-label"
            value={selectedOperator}
            onChange={handleOperatorChange}
          >
            {operators.map((operator) => (
              <MenuItem key={operator.operator_id} value={operator.operator_id}>
                {operator.operator_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {dispatches.length > 0 ? (
          <TableContainer component={Paper}>
            <Table sx={{ '& .MuiTableCell-root': { padding: { xs: '8px', sm: '16px' } } }}>
              <TableHead>
                <TableRow>
                  <TableCell><strong>Plaka</strong></TableCell>
                  <TableCell><strong>Sürücü Adı</strong></TableCell>
                  <TableCell><strong>Durum</strong></TableCell>
                  <TableCell><strong>Atama Zamanı</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dispatches.map((dispatch, index) => (
                  <TableRow key={index} onClick={() => handleRowClick(dispatch)}>
                    <TableCell>{dispatch.plaka}</TableCell>
                    <TableCell>{dispatch.driver_name}</TableCell>
                    <TableCell>{dispatch.status}</TableCell>
                    <TableCell>{formatDateToTR(dispatch.assigned_at)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography align="center">Henüz veri bulunamadı.</Typography>
        )}

        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box sx={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
            width: { xs: '90%', sm: 400 },
            backgroundColor: 'white',
            padding: { xs: '10px', sm: '20px' },
            boxShadow: 24,
          }}>
            <Typography variant="h6" id="simple-modal-title">Durum Güncelle</Typography>
            <FormControl fullWidth sx={{ marginBottom: { xs: '10px', sm: '20px' } }}>
              <InputLabel id="status-select-label">Durum Seç</InputLabel>
              <Select
                labelId="status-select-label"
                value={selectedStatus}
                onChange={handleStatusChange}
              >
                <MenuItem value="YÜKLENDİ">YÜKLENDİ</MenuItem>
                <MenuItem value="GELMEDİ">GELMEDİ</MenuItem>
              </Select>
            </FormControl>
            <Button variant="contained" color="primary" sx={{ marginTop: { xs: '10px', sm: '20px' } }} onClick={handleUpdateStatus}>
              Güncelle
            </Button>
          </Box>
        </Modal>
      </Paper>
    </Container>
  );
};

export default OperatorDispatchesPage;